.am-picker-popup {
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
}
.am-picker-popup-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  -webkit-transform: translateZ(1px);
          transform: translateZ(1px);
}
.am-picker-popup-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  z-index: 1000;
  -webkit-transform: translateZ(1px);
          transform: translateZ(1px);
}
.am-picker-popup-mask-hidden {
  display: none;
}
.am-picker-popup-header {
  background-image: -webkit-linear-gradient(top, #e7e7e7, #e7e7e7, transparent, transparent);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e7e7e7), color-stop(#e7e7e7), color-stop(transparent), to(transparent));
  background-image: linear-gradient(to bottom, #e7e7e7, #e7e7e7, transparent, transparent);
  background-position: bottom;
  background-size: 100% 1PX;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  border-bottom: 1PX solid #ddd;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-picker-popup-header {
    border-bottom: none;
  }
  html:not([data-scale]) .am-picker-popup-header::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
            transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-picker-popup-header::after {
    -webkit-transform: scaleY(0.33);
        -ms-transform: scaleY(0.33);
            transform: scaleY(0.33);
  }
}
.am-picker-popup-header .am-picker-popup-header-right {
  text-align: right;
}
.am-picker-popup-item {
  color: #108ee9;
  font-size: 17px;
  padding: 9px 15px;
  height: 42px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.am-picker-popup-item-active {
  background-color: #ddd;
}
.am-picker-popup-title {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  color: #000;
}
.am-picker-popup .am-picker-popup-close {
  display: none;
}
.am-picker-col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
